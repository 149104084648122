<template>
  <div class="flex flex-col">
    <QuillEditor
      v-bind="$attrs"
      ref="editorComponent"
      v-model:content="content"
      theme="snow"
      :toolbar="toolbarToRender"
      :placeholder="placeholder"
      :modules="modules"
      :enable="!disabled"
      @text-change="handleTextChange"
      @update:content="emit('update:content', $event)"
      @selection-change="emit('selection:change')"
      @ready="emit('editor:ready')"
    >
      <template v-slot:toolbar v-if="$slots.customToolbar">
        <div :id="`custom-toolbar--${name}`">
          <slot name="customToolbar" />
        </div>
      </template>
    </QuillEditor>
    <div
      v-if="errorMessage"
      class="pl-2 pt-2 text-xxs sm:text-xs text-red-700 text-left"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>
<script setup lang="ts">
import {
  onMounted,
  ref,
  getCurrentInstance,
  computed,
  watch,
  type PropType
} from "vue";
import { useField } from "vee-validate";
import { Quill, Delta, QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { WYSIWYG_TOOLBAR } from "@/helpers/constants";
import { type EditorModule, StyledMentionBlot } from "@/lib/Quill";

/* eslint-disable @typescript-eslint/no-explicit-any */
const slots = defineSlots<{
  customToolbar?: (props: Record<string, never>) => any;
}>();
/* eslint-enable @typescript-eslint/no-explicit-any */

const props = defineProps({
  value: {
    type: [String, Delta],
    required: true
  },
  name: {
    type: String,
    required: true
  },
  placeholder: {
    type: String,
    default: ""
  },
  disabled: {
    type: Boolean,
    default: false
  },
  toolbar: {
    type: [Object, Array],
    default: () => [...WYSIWYG_TOOLBAR]
  },
  modules: {
    type: Array as PropType<EditorModule[]>
  }
});

const emit = defineEmits<{
  "update:content": [value: string];
  "selection:change": [void];
  "editor:ready": [void];
}>();

const editorComponent = ref<InstanceType<typeof QuillEditor> | null>(null);
const content = ref(props.value ?? "");

Quill.register(StyledMentionBlot);

const editorInstance = computed<Quill | null>(
  () => editorComponent.value?.getQuill() || null
);
const { errorMessage, handleChange } = useField(props.name, undefined);
const emitter =
  getCurrentInstance()?.appContext.config.globalProperties.emitter;

const insertText = (text: string) => {
  editorInstance.value?.insertText(editorInstance.value.getLength(), text);
};
const deleteText = (text: string) => {
  editorInstance.value?.deleteText(
    editorInstance.value.getText().indexOf(text),
    text.length + 1
  );
};

const handleTextChange = () => {
  handleChange(editorInstance.value?.root.innerHTML);
};

const toolbarToRender = computed(() => {
  if (slots.customToolbar) {
    return `#custom-toolbar--${props.name}`;
  }

  return props.toolbar ?? WYSIWYG_TOOLBAR;
});

onMounted(() => {
  handleChange(editorInstance.value?.root.innerHTML);
  emitter.on("insert-text", (text: string) => insertText(text));
  emitter.on("remove-text", (text: string) => deleteText(text));
});

watch(
  () => props.value,
  (newValue) => {
    content.value = newValue;
  }
);

defineExpose({ editorInstance, content });
</script>

<style>
.ql-container {
  @apply max-h-52;
  min-height: 130px !important;
}
</style>
